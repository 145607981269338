import React, {useEffect, useState} from 'react';
import '../styles/Login.css';
import EyeOpenIcon from '../svgs/eye-password-show.svg';
import EyeClosedIcon from '../svgs/eye-password-hide.svg';


interface LoginProps {
    onLogin: (email: string, accessToken: string, refreshToken: string, idToken: string, expiresIn: number) => void;
}

export const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
    const [rememberUsername, setRememberUsername] = useState(false);
    const [error, setError] = useState<string>('');
    const [requiresNewPassword, setRequiresNewPassword] = useState(false);
    const [challengeSessionId, setChallengeSessionId] = useState<string>('');
    const baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL;

    useEffect(() => {
        const savedUsername = localStorage.getItem('rememberedUsername');
        if (savedUsername) {
            setUsername(savedUsername);
            setRememberUsername(true);
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (rememberUsername) {
            localStorage.setItem('rememberedUsername', username);
        } else {
            localStorage.removeItem('rememberedUsername');
        }
        try {
            const response = await fetch(`${baseServerUrl}/stylist-auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            }).then(response => response.json());
            console.log(response);
            if(response.signInResponse.$metadata.httpStatusCode === 200) {
                if(response.signInResponse.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    setChallengeSessionId(response.signInResponse.Session);
                    setRequiresNewPassword(true);
                } else {
                    const userAttributes = response.getUserResponse.UserAttributes;
                    let email = '';
                    for (let i = 0; i < userAttributes.length; i++) {
                        if (userAttributes[i].Name === 'email') {
                            email = userAttributes[i].Value;
                            break;
                        }
                    }
                    let accessToken = response.signInResponse.AuthenticationResult.AccessToken;
                    let expiresIn = response.signInResponse.AuthenticationResult.ExpiresIn;
                    let idToken = response.signInResponse.AuthenticationResult.IdToken;
                    let refreshToken = response.signInResponse.AuthenticationResult.RefreshToken;

                    onLogin(email, accessToken, refreshToken, idToken, expiresIn);
                }
            } else {
                alert("Invalid username or password");
            }
        } catch (error: any) {
            console.log("error: ", error);
            setError(error.message || 'Error logging in');
            alert("Server error");
        }
    };

    const handlePasswordUpdate = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== newPasswordConfirm) {
            alert("Passwords do not match");
            return;
        }
        // Regex for password requirements
        const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\s]).{8,}$/;
        if (!passwordRegex.test(newPassword)) {
            alert("Password does not meet requirements");
            return;
        }

        try {
            const response = await fetch(`${baseServerUrl}/stylist-auth/respond-to-auth-challenge`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password: newPassword, session: challengeSessionId}),
            }).then(response => response.json());
            console.log(response);
            if (response.authChallengeResponse.$metadata.httpStatusCode === 200) {
                alert("Password updated successfully");
                console.log(response);
                setRequiresNewPassword(false);
                const userAttributes = response.getUserResponse.UserAttributes;
                let email = '';
                for (let i = 0; i < userAttributes.length; i++) {
                    if (userAttributes[i].Name === 'email') {
                        email = userAttributes[i].Value;
                        break;
                    }
                }
                let accessToken = response.authChallengeResponse.AuthenticationResult.AccessToken;
                let expiresIn = response.authChallengeResponse.AuthenticationResult.ExpiresIn;
                let idToken = response.authChallengeResponse.AuthenticationResult.IdToken;
                let refreshToken = response.authChallengeResponse.AuthenticationResult.RefreshToken;

                onLogin(email, accessToken, refreshToken, idToken, expiresIn);
            } else {
                console.log("error: ", response);
                alert("Error updating password.");
            }
        } catch (error: any) {
            console.log("error: ", error);
            setError(error.message || 'Error updating password');
            alert("Server error");
        }
    };


    return (
        <div className="login-container">
            <div className="login-form">
                {!requiresNewPassword ? (
                    <>
                    <h2>Welcome back!</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                placeholder={"Email address"}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder={"Password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <img
                                src={showPassword ? EyeOpenIcon : EyeClosedIcon}
                                alt="Toggle Password Visibility"
                                className="eye-icon"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <div className="checkbox-container">
                            <label>
                            <input
                                type="checkbox"
                                checked={rememberUsername}
                                onChange={() => setRememberUsername(!rememberUsername)}
                            />
                                <span>Remember me</span>
                            </label>
                            <a href="#" className="forgot-password">Forgot password?</a>
                        </div>
                        <button type="submit">Log in</button>
                    </form>
                    </>) : (
                        <>
                        <h5>Update your password to continue</h5>
                        <form onSubmit={handlePasswordUpdate}>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder={"New Password"}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                                <img
                                    src={showNewPassword ? EyeOpenIcon : EyeClosedIcon}
                                    alt="Toggle Password Visibility"
                                    className="eye-icon"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showNewPasswordConfirm ? "text" : "password"}
                                    placeholder={"Confirm Password"}
                                    value={newPasswordConfirm}
                                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                                    required
                                />
                                <img
                                    src={showNewPasswordConfirm ? EyeOpenIcon : EyeClosedIcon}
                                    alt="Toggle Password Visibility"
                                    className="eye-icon"
                                    onClick={() => setShowNewPasswordConfirm(!showNewPasswordConfirm)}
                                />
                            </div>
                            <button type="submit" style={{width: 'fit-content'}}>Update Password</button>
                            <div className="password-requirements">
                                <h5>Password requirements:</h5>
                                <ul className={'list-unstyled'}>
                                    <li>8-character minimum length</li>
                                    <li>Contains at least 1 number</li>
                                    <li>Contains at least 1 lowercase letter</li>
                                    <li>Contains at least 1 uppercase letter</li>
                                    <li>Contains at least 1 special character from the following set, or a non-leading, non-trailing space character:</li>
                                    ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \
                                </ul>
                            </div>
                        </form>
                        </>
                    )}
            </div>
        </div>
    );
};
