import React, {useState, useEffect} from 'react';
import './App.css';
import {Dashboard} from "./componenets/Dashboard";
import {Login} from "./componenets/Login";

function App() {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [refreshToken, setRefreshToken] = useState<string | undefined>("");
    const [idToken, setIdToken] = useState<string | undefined>("");
    const [expiresIn, setExpiresIn] = useState<number | undefined>(0);

    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedAccessToken = localStorage.getItem('accessToken');
        const savedRefreshToken = localStorage.getItem('refreshToken');
        const savedIdToken = localStorage.getItem('idToken');
        const savedExpiresIn = localStorage.getItem('expiresIn');
        if (savedAccessToken) {
            setAccessToken(savedAccessToken);
        }
        if(savedEmail) {
            setEmail(savedEmail);
        }
    }, []);

    const handleLogin = (email: string,
                         accessToken: string,
                         refreshToken: string,
                         idToken: string,
                         expiresIn: number) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('email', email);
        setEmail(email);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setIdToken(idToken);
        setExpiresIn(expiresIn);
    };

    const handleLogout = async () => {
        // Call logout service
        try {
            let response = await fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/stylist-auth/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ accessToken: accessToken }),
            }).then(response => response.json());
            console.log(response);
        } catch (e) {
            console.error(e);
        }
        setAccessToken(undefined);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
    };

    return (
        <div className="App">
            {accessToken ? (
                    <Dashboard onLogout={handleLogout} email={email as string}/>
            ) : (
                <Login onLogin={handleLogin} />
            )}
        </div>
    );

}

export default App;
