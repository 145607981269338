import React from 'react';
import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import '../styles/StoreConnection.css';

interface StoreConnectionProps {
    email: string;
    onLogout: () => void;

}

export const StoreConnection: React.FC<StoreConnectionProps> = ({email, onLogout}) => {
    const [storeUrl, setStoreUrl] = React.useState<string>('');
    const [storeName, setStoreName] = React.useState<string>('');
    const [storeApiKey, setStoreApiKey] = React.useState<string>('');

    const handleConnectToStore = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            // Connect to the store
            const createStoreResponse = await fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/store/create-store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    storeUrl: storeUrl,
                    storeName: storeName,
                    storeApiKey: storeApiKey,
                }),
            }).then(response => response.json());
            console.log(createStoreResponse);
            if(createStoreResponse.storeCreated) {
                alert('Store connected successfully');
                window.location.reload();
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={"store-connection-container"}>
            <div className="store-connection-form">
                <Form onSubmit={handleConnectToStore}>
                    <h2>Store Connection</h2>
                    <h6>Connect your store to complete sign up</h6>
                    <FloatingLabel label={'Store Name'} className="mb-3 mt-4">
                        <Form.Control type="text" placeholder="Store Name" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label={'Store URL'} className="mb-3">
                        <Form.Control type="text" placeholder="Store URL" value={storeUrl} onChange={(e) => setStoreUrl(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel label={'Store API Key'} className="mb-3">
                        <Form.Control type="text" placeholder="Store API Key" value={storeApiKey} onChange={(e) => setStoreApiKey(e.target.value)} />
                    </FloatingLabel>
                    <Button type={"submit"}>Connect to Store</Button>
                </Form>
                <Button type={"button"} style={{marginTop: '1%'}} variant={"secondary"}
                        onClick={onLogout}>Logout</Button>
            </div>
        </div>
    );
}