import React, {useState} from 'react';
import styles from '../styles/Settings.module.css';



export const Settings: React.FC = () => {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [storeUrl, setStoreUrl] = useState<string>("");
    const [apiKey, setApiKey] = useState<string>("");
    const [isSaving, setIsSaving] = useState(false);

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        // Save the settings
        console.log(storeUrl, apiKey);
    };

    return (
        <div className={styles.settings}>
            <h2>Settings</h2>
            <form onSubmit={handleSave} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="storeUrl">Store URL:</label>
                    <input
                        type="text"
                        id="storeUrl"
                        value={storeUrl}
                        onChange={(e) => setStoreUrl(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="apiKey">API Key:</label>
                    <input
                        type="text"
                        id="apiKey"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className={styles.saveButton} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save'}
                </button>
            </form>
        </div>
    );
};