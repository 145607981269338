import React, {useEffect} from 'react';
import styles from '../styles/Orders.module.css';
import {Product, Variant} from "./Dashboard";
import {OrderDetails} from "./OrderDetail";

export interface Order {
    id: number;
    orderNumber: number;
    orderTimestamp: string;
    customerName: string;
    orderTotalShipping: number;
    orderTotalTax: number;

    orderTotal: number;
    orderStatus: string;
    shippingAddress: {
        address1?: string;
        address2?: string;
        city?: string;
        province?: string;
        provinceCode?: string;
        country?: string;
        countryCode?: string;
        zip?: string;
        phone?: string;
        email?: string;
        company?: string;
    }
    orderItems: OrderItem[];
}

export interface OrderItem {
    id: number;
    curatorId: number;
    sourceProductId: number;
    sourceVariantId: number;
    orderQuantity: number;
    product: Product;
    variant: Variant;
}


export const Orders: React.FC = () => {
    const [orders, setOrders] = React.useState([]);
    const [selectedOrder, setSelectedOrder] = React.useState<Order | null>(null);

    useEffect(() => {
        // Fetch orders from the server
        const fetchOrders = async () => {
            console.log(`${process.env.REACT_APP_BASE_SERVER_URL}`);
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/orders/curator-orders?` + new URLSearchParams({
                    storeId: "1",
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(response);
                const data = await response.json();
                console.log(data);
                setOrders(data);
            } catch (e) {
                console.error(e);
            }
        }

        fetchOrders();
    }, []);

    const convertTimestamp = (timestamp: string) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    const handleViewOrderDetails = (order: Order) => {
        setSelectedOrder(order);
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
    };

    const handleFulfillOrder = async (order: Order) => {
        // Call the server to fulfill the order
        console.log('Fulfilling order...', order);
        try {
            if (order.orderStatus !== 'PENDING_PAYMENT') {
                alert('Order is already paid for');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/orders/place-order-with-supplier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ orderId: order.id }),
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                order.orderStatus = 'PROCESSING'
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.orders}>
            <table className={styles.ordersTable}>
                <thead>
                <tr>
                    <th>Order Number</th>
                    <th>Order Date</th>
                    <th>Customer Name</th>
                    <th>Order Total</th>
                    <th>Order Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {orders.map((order: any) => {
                    return (<>
                        <tr key={order.id}>
                            <td>{order.orderNumber}</td>
                            <td>{convertTimestamp(order.orderTimestamp)}</td>
                            <td>{order.customerName}</td>
                            <td>${order.orderTotal}</td>
                            <td>{order.orderStatus}</td>
                            <td>
                                <button className={styles.viewButton} onClick={() => handleViewOrderDetails(order)}
                                >View Order Details</button>
                                <br/>
                                <button className={styles.fulfillButton} onClick={() => handleFulfillOrder(order)}
                                        disabled={order.orderStatus !== 'PENDING_PAYMENT'}>Fulfill</button>
                            </td>
                        </tr>
                    </>)
                })}
                </tbody>
            </table>
            {selectedOrder && <OrderDetails order={selectedOrder} onClose={handleCloseModal} onFulfillOrder={handleFulfillOrder}/>}
        </div>
    );
}