import React, {useState} from 'react';
import { Product } from './Dashboard'; // Make sure this import path is correct
import styles from '../styles/ImportedProducts.module.css'; // Update this path to your actual CSS module

interface ImportedProductsProps {
    products: Product[];
    onRemove: (product: Product) => void;
    onSyncInventory: () => void;
}


const ImportedProducts: React.FC<ImportedProductsProps> = ({ products, onRemove, onSyncInventory }) => {
    const [expandedProducts, setExpandedProducts] = useState<Set<number>>(new Set());

    const toggleVariants = (productId: number) => {
        setExpandedProducts(prev => {
            const newSet = new Set(prev);
            if (newSet.has(productId)) {
                newSet.delete(productId);
            } else {
                newSet.add(productId);
            }
            return newSet;
        });
    };

    return (
        <div className={styles.importedProducts}>
            <table className={styles.productTable}>
                <thead>
                <tr>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Supplier</th>
                    <th>Brand</th>
                    <th>Variant In Stock</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {products.map((product) => {
                    let image = "";
                    if(product.images.length > 0) {
                        image = product.images[0].src;
                    }

                    const isExpanded = expandedProducts.has(product.id);
                    console.log("variants", product.variants);

                    return (
                        <>
                            <tr key={product.id}>
                                <td><img src={image} alt={product.title} className={styles.thumbnail} /></td>
                                <td>{product.title}</td>
                                <td>{product.supplierName}</td>
                                <td>{product.brand}</td>
                                <td>-</td>
                                <td>
                                    <button onClick={() => onRemove(product)} className={styles.removeButton}>Remove</button>
                                    <button onClick={() => toggleVariants(product.id)} className={styles.toggleButton}>
                                        {isExpanded ? 'Hide Variants' : 'Show Variants'}
                                    </button>
                                </td>
                            </tr>
                            {isExpanded && product.variants && product.variants.map(variant => (
                                <tr key={variant.variantShopifyId} className={styles.variantRow}>
                                    <td><img src={variant.images[0] ? variant.images[0].src :
                                        "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"} alt={variant.title} className={styles.thumbnail} /></td>
                                    <td><b>(variant)</b> {variant.title}</td>
                                    <td>{product.supplierName}</td>
                                    <td>{product.brand}</td>
                                    <td>
                                        In Stock
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                        </>
                    )
                })}
                </tbody>
            </table>
            <button className={styles.syncInventoryBtn} onClick={onSyncInventory}>
                Sync Inventory
            </button>
        </div>
    );
};

export default ImportedProducts;
