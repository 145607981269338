// OrderDetails.js
import React from 'react';
import styles from '../styles/OrderDetails.module.css';
import {Order} from "./Orders";



interface OrderDetailProps {
    order: Order;
    onClose: () => void;

    onFulfillOrder: (order: Order) => void;
}

export const OrderDetails: React.FC<OrderDetailProps> = ({ order, onClose, onFulfillOrder }) => {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.close} onClick={onClose}>&times;</span>
                <h2>Order #{order.orderNumber}</h2>
                <p><strong>Order Date:</strong> {new Date(order.orderTimestamp).toLocaleString()}</p>
                <p><strong>Customer Details:</strong></p>
                <p>{order.customerName}</p>
                <p>{order.shippingAddress.phone}</p>
                <p>{order.shippingAddress.address1}</p>
                <p>{order.shippingAddress.address2}</p>
                <p>{order.shippingAddress.city}, {order.shippingAddress.zip}</p>
                <p>{order.shippingAddress.country}</p>
                <table>
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th>Variant</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {order.orderItems.map(item => (
                        <tr key={item.id}>
                            <td>{item.product.title}</td>
                            <td>{item.variant.title}</td>
                            <td>${item.variant.price}</td>
                            <td>{item.orderQuantity}</td>
                            <td>${item.variant.price * item.orderQuantity}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <p><strong>Shipping:</strong> ${order.orderTotalShipping}</p>
                <p><strong>Tax:</strong> ${order.orderTotalTax}</p>
                <p><strong>Total Amount:</strong> ${order.orderTotal}</p>
                <button className={styles.fulfillButton}
                        disabled={order.orderStatus !== 'PENDING_PAYMENT'}
                        onClick={() => onFulfillOrder(order)}>Fulfill Order</button>
            </div>
        </div>
    );
};
