import React, {useEffect, useState} from 'react';
import '../styles/Dashboard.css';
import ItemCard from "./ItemCard";
import ImportedProducts from "./ImportedProducts";
import {Orders} from "./Orders";
import {Settings} from "./Settings";
import {StoreConnection} from "./StoreConnection";

interface DashboardProps {
    onLogout: () => void;
    email: string;
}

export interface Product {
    brand: string;
    description: string;
    handle?: string;
    id: number;
    images: Image[];
    productCategory?: string;
    productShopifyId?: string;
    productType?: string;
    shopifyShopUrl?: string;
    title: string;
    variants?: Variant[];
    price: number;
    supplierName: string;
}

export interface Image {
    id: number;
    productShopifyId: string;
    src: string;
    altText: string;
}

export interface Variant {
    variantShopifyId: string;
    productShopifyId: string;
    title: string;
    price: number;
    images: Image[];
}

export const Dashboard: React.FC<DashboardProps> = ({ onLogout, email }) => {

    const [products, setProducts] = useState<Product[]>([]);
    const [selectedView, setSelectedView] = useState(0);
    const [importedProducts, setImportedProducts] = useState(new Set<number>());
    const baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL;
    const [isConnectedToStore, setIsConnectedToStore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStoreConnection = async () => {
            try {
                const response = await fetch(`${baseServerUrl}/store/get-curator-store-info?` + new URLSearchParams({
                    email: email,
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                console.log(data);
                setIsConnectedToStore(data.isConnectedToStore);
            } catch (error) {
                console.error('Error fetching store connection:', error);
            }
        }

        const fetchProducts = async () => {
            try {
                const response = await fetch(`${baseServerUrl}/products/all-products`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setProducts(data);
                console.log(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchAlreadyImportedProducts = async () => {
            try {
                //make get request and pass in email into query params
                const response = await fetch(`${baseServerUrl}/products/curator-products?` + new URLSearchParams({
                    email: email,
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const importedProductIds = await response.json();
                setImportedProducts(new Set(importedProductIds));

            } catch (error) {
                console.error('Error fetching imported products:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchProducts();
        fetchAlreadyImportedProducts();
        fetchStoreConnection();
    }, []);

    const handleLogout = async() => {
        onLogout();
    }

    const handleAddProduct = (product: Product) => {
        console.log('Adding product', product.id);
        setImportedProducts(new Set(importedProducts.add(product.id)));
    };

    const handleRemoveProduct = (product: Product) => {
        importedProducts.delete(product.id);
        setImportedProducts(new Set(importedProducts));
    };

    const handleUpdateView = (view: number) => {
        setSelectedView(view);
    }

    const handleSyncInventory = async () => {
        console.log('Syncing inventory');

        //make a post request to sync inventory with imported products
        //send the set of product ids to the server
        const importedProductIds = Array.from(importedProducts);
        let response = await fetch(`${baseServerUrl}/products/sync-curator-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email, productIds: importedProductIds}),
        });
        if(response.ok) {
            alert("Inventory synced successfully");
        } else {
            alert("Error syncing inventory");
        }

    }

    if(isLoading) {
        return (<div>Loading...</div>);
    }

    return (
        <>
            {isConnectedToStore ? (
            <div className="dashboard-container">
                <div className="dashboard-sidebar">
                    <ul>
                        <li onClick={() => handleUpdateView(0)}>Available Products</li>
                        <li onClick={() => handleUpdateView(1)}>Imported Items</li>
                        <li onClick={() => handleUpdateView(2)}>Orders</li>
                        {/*<li onClick={() => handleUpdateView(3)}>Settings</li>*/}
                        <li onClick={handleLogout}>Logout</li>
                    </ul>
                </div>
                <div className="dashboard">
                    {
                        selectedView === 0 && (
                            <>
                                <h1>Available products</h1>
                                <div className="product-list">
                                    {products.map(product => (
                                        <ItemCard product={product} key={product.id} onAddProduct={handleAddProduct} importedProducts={importedProducts}/>
                                    ))}
                                </div>
                            </>
                    )
                    }
                    {selectedView === 1 && <ImportedProducts
                        products={products.filter(product => importedProducts.has(product.id))}
                        onRemove={handleRemoveProduct}
                        onSyncInventory={handleSyncInventory}
                    />}
                    {selectedView === 2 && <Orders/>}
                    {selectedView === 3 && <Settings/>}
                </div>
            </div>) : (<StoreConnection email={email} onLogout={onLogout}/>) }
        </>
    );
};
