import React, {useEffect, useState} from 'react';
import { Product } from './Dashboard';
import '../styles/ItemCard.css';

interface ItemCardProps {
    product: Product;
    importedProducts: Set<number>;
    onAddProduct: (product: Product) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ product, importedProducts, onAddProduct}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        setDisabled(importedProducts.has(product.id));
    }, [importedProducts, product.id]);

    // Define a state to control the disabled property of the button
    const [isDisabled, setDisabled] = useState(importedProducts.has(product.id));

    const handleImageChange = (next: boolean) => {
        setCurrentImageIndex((prevIndex) => {
            if (next) {
                return (prevIndex + 1) % product.images.length;
            } else {
                return (prevIndex - 1 + product.images.length) % product.images.length;
            }
        });
    };

    return (
        <div className="item-card">
            <div className="image-container">
                <img src={product.images[currentImageIndex] ? product.images[currentImageIndex].src :
                    "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"} alt={product.title} />
                {product.images.length > 1 && (
                    <>
                        <button className="prev" onClick={() => handleImageChange(false)}>❮</button>
                        <button className="next" onClick={() => handleImageChange(true)}>❯</button>
                    </>
                )}
            </div>
            <h3>{product.title}</h3>
            <p>From {product.supplierName}</p>
            <div className="cost">
                <span className="supplier-price">${product.price}</span>
                <span className={"brand"}>{product.brand}</span>
            </div>
            <button disabled={isDisabled} onClick={() => onAddProduct(product)}>
                Add to store
            </button>
        </div>
    );
};

export default ItemCard;
